import React from 'react';

function iPad() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" fill="white" viewBox="0 0 24 24">
      <path d="M18.5 0h-14A2.5 2.5 0 002 2.5v19A2.5 2.5 0 004.5 24h14a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0018.5 0zm-7 23c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7.5-4H4V3h15v16z"></path>
    </svg>
    )
}

export default iPad;