import './App.css';
import Crown from './components/Crown.js';
import Nav from './components/Nav.js';

function App() {
  return (
    <>
      <Nav/>
      <Crown/>
    </>
  );
}

export default App;
